import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { defaultMeta } from "utils/Mocks/Common";
import CommonSlice from "./CommonSlice";
import UserApi from "apis/UserApi";

const initialState = {
  meta: defaultMeta as MetaType,
  users: [] as UserType[],
  userPredictPnl: null as UserPredictPnlType | null,
  userTokenPredictPnl: null as UserPredictPnlType | null,
  userEIQTPredictPnl: null as UserPredictPnlType | null,
  detail: null as UserType | null,
  transactionHistory: {
    datas: [] as any[],
    meta: null as MetaType | null,
  },
  friendVolume: {
    datas: [] as FriendUserType[],
    meta: null as MetaType | null,
  },
  commissionRebate: {
    datas: [] as CommissionRebase[],
    meta: null as MetaType | null,
  },
  predictHistory: {
    datas: [] as PredictHistoryType[],
    meta: null as MetaType | null,
  },
  stakeHistory: {
    datas: [] as StakeHistoryType[],
    meta: defaultMeta as MetaType,
  },
  interestHistory: {
    datas: [] as InterestHistoryType[],
    meta: defaultMeta as MetaType,
  },
  commissionHistory: {
    datas: [] as CommissionHistoryType[],
    meta: defaultMeta as MetaType,
  },
  isRefresh: false as boolean,
    farmingHistory: {
        datas: [] as any[],
        meta: defaultMeta as MetaType,
    },
  farmingCommissionHistory: {
    datas: [] as any[],
    meta: defaultMeta as MetaType,
  },
  farmingInterestHistory: {
    datas: [] as any[],
    meta: defaultMeta as MetaType,
  },
};

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUsers(params);
      const { data } = response;
      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserDetail = createAsyncThunk(
  "users/getUserDetail",
  async (userId: string, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserDetail(userId);
      const { data } = response;
      return data.data;
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.updateUser(params);
      const { data } = response;
      if (data.meta.status === 200) {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update user successful!",
          })
        );
        return data.data;
      } else {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "error",
            message: "Error!",
            description: data.meta.externalMessage || "",
          })
        );
      }
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserPredictPnl = createAsyncThunk(
  "users/getUserPredictPnl",
  async (userId: string, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserPredictPnl(userId, "USDT");
      const { data } = response;
      return (data.data || []) as UserPredictPnlType[];
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserTokenPredictPnl = createAsyncThunk(
  "users/getUserTokenPredictPnl",
  async (userId: string, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserPredictPnl(userId, "IQT");
      const { data } = response;
      return (data.data || []) as UserPredictPnlType[];
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserEIQTPredictPnl = createAsyncThunk(
  "users/getUserEIQTPredictPnl",
  async (userId: string, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserPredictPnl(userId, "eIQT");
      const { data } = response;
      return (data.data || []) as UserPredictPnlType[];
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserFriendVolume = createAsyncThunk(
  "users/getUserFriendVolume",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserFriendVolume(params);
      const { data } = response;
      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserCommissionRebate = createAsyncThunk(
  "users/getUserCommissionRebate",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserCommissionRebate(params);
      const { data } = response;
      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserPredictHistories = createAsyncThunk(
  "users/getUserPredictHistories",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserPredictHistories(params);
      const { data } = response;
      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserCoinTransactionHistories = createAsyncThunk(
  "users/getUserCoinTransactionHistories",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserCoinTransactionHistories(params);
      const { data } = response;
      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserStakeHistories = createAsyncThunk(
  "users/getUserStakeHistories",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserStakeHistories(params);
      const { data } = response;
      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserFarmingHistories = createAsyncThunk(
    "users/getUserFarmingHistories",
    async (params: any, thunkApi: any) => {
        thunkApi.dispatch(CommonSlice.actions.incrementLoading());
        try {
            const response = await UserApi.getUserFarmingHistories(params);
            const { data } = response;
            const meta = {
                page: data.data.page || 0,
                pageSize: data.data.pageSize || 0,
                total: data.data.total || 0,
            };
            return {
                meta: meta,
                datas: data.data.datas || [],
            };
        } catch (error) {
            return null;
        } finally {
            thunkApi.dispatch(CommonSlice.actions.decrementLoading());
        }
    }
);

export const getUserInterestHistories = createAsyncThunk(
  "users/getUserInterestHistories",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserInterestHistories(params);
      const { data } = response;
      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserCommissionHistories = createAsyncThunk(
  "users/getUserCommissionHistories",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserCommissionHistories(params);
      const { data } = response;
      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserFarmingCommissionHistories = createAsyncThunk(
  "users/getUserFarmingCommissionHistories",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserFarmingCommissionHistories(params);
      const { data } = response;
      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const getUserFarmingInterestHistories = createAsyncThunk(
  "users/getUserFarmingInterestHistories",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.getUserFarmingInterestHistories(params);
      const { data } = response;
      const meta = {
        page: data.data.page || 0,
        pageSize: data.data.pageSize || 0,
        total: data.data.total || 0,
      };
      return {
        meta: meta,
        datas: data.data.datas || [],
      };
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

export const updateVip = createAsyncThunk(
  "users/updateVip",
  async (params: any, thunkApi: any) => {
    thunkApi.dispatch(CommonSlice.actions.incrementLoading());
    try {
      const response = await UserApi.updateVip(params);
      const { data } = response;
      if (data.meta.status === 200) {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update user successful!",
          })
        );
        return data.data;
      } else {
        thunkApi.dispatch(
          CommonSlice.actions.showNotice({
            type: "error",
            message: "Error!",
            description: data.meta.externalMessage || "",
          })
        );
      }
    } catch (error) {
      return null;
    } finally {
      thunkApi.dispatch(CommonSlice.actions.decrementLoading());
    }
  }
);

const UserSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setIsRefresh: (state, action: PayloadAction<any>) => {
      state.isRefresh = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      if (action.payload) {
        state.meta = action.payload.meta;
        state.users = action.payload.datas;
      }
    });
    builder.addCase(getUserPredictPnl.fulfilled, (state, action) => {
      if (action.payload) {
        state.userPredictPnl = action.payload[0];
      }
    });
    builder.addCase(getUserTokenPredictPnl.fulfilled, (state, action) => {
      if (action.payload) {
        state.userTokenPredictPnl = action.payload[0];
      }
    });
    builder.addCase(getUserEIQTPredictPnl.fulfilled, (state, action) => {
      if (action.payload) {
        state.userEIQTPredictPnl = action.payload[0];
      }
    });
    builder.addCase(getUserFriendVolume.fulfilled, (state, action) => {
      if (action.payload) {
        state.friendVolume.meta = action.payload.meta;
        state.friendVolume.datas = action.payload.datas;
      }
    });
    builder.addCase(getUserCommissionRebate.fulfilled, (state, action) => {
      if (action.payload) {
        state.commissionRebate.meta = action.payload.meta;
        state.commissionRebate.datas = action.payload.datas;
      }
    });
    builder.addCase(getUserPredictHistories.fulfilled, (state, action) => {
      if (action.payload) {
        state.predictHistory.meta = action.payload.meta;
        state.predictHistory.datas = action.payload.datas;
      }
    });
    builder.addCase(getUserCoinTransactionHistories.fulfilled, (state, action) => {
      if (action.payload) {
        state.transactionHistory.meta = action.payload.meta;
        state.transactionHistory.datas = action.payload.datas;
      }
    });
    builder.addCase(getUserDetail.fulfilled, (state, action) => {
      if (action.payload) {
        state.detail = action.payload;
      }
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      if (action.payload) {
        state.detail = action.payload;
      }
    });
    builder.addCase(getUserStakeHistories.fulfilled, (state, action) => {
      if (action.payload) {
        state.stakeHistory.meta = action.payload.meta;
        state.stakeHistory.datas = action.payload.datas;
      }
    });
    builder.addCase(getUserInterestHistories.fulfilled, (state, action) => {
      if (action.payload) {
        state.interestHistory.meta = action.payload.meta;
        state.interestHistory.datas = action.payload.datas;
      }
    });
    builder.addCase(getUserCommissionHistories.fulfilled, (state, action) => {
      if (action.payload) {
        state.commissionHistory.meta = action.payload.meta;
        state.commissionHistory.datas = action.payload.datas;
      }
    });
    builder.addCase(updateVip.fulfilled, (state, action) => {
      if (action.payload) {
        state.isRefresh = true;
      }
    });
    builder.addCase(getUserFarmingHistories.fulfilled, (state, action) => {
        if (action.payload) {
            state.farmingHistory.meta = action.payload.meta;
            state.farmingHistory.datas = action.payload.datas;
        }
    });
    builder.addCase(getUserFarmingCommissionHistories.fulfilled, (state, action) => {
      if (action.payload) {
        state.farmingCommissionHistory.meta = action.payload.meta;
        state.farmingCommissionHistory.datas = action.payload.datas;
      }
    });
    builder.addCase(getUserFarmingInterestHistories.fulfilled, (state, action) => {
      if (action.payload) {
        state.farmingInterestHistory.meta = action.payload.meta;
        state.farmingInterestHistory.datas = action.payload.datas;
      }
    });
  },
});

export default UserSlice;
