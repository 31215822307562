import { Button, Card, Form, InputNumber, Row, Col  } from "antd";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "stores";
import SettingApi from "apis/SettingApi";
import CommonSlice from "stores/CommonSlice";

export default function EIQTVolumeBotSetting() {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { setting } = useAppSelector((state) => state.setting);
  const volumeBot: any = setting.find((item) => item.key === "eiqt_volume_bot_config");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (volumeBot) {
      form.setFieldsValue({
        lowerFrom: volumeBot.value.lowerRange.from,
        lowerTo: volumeBot.value.lowerRange.to,
        higherFrom: volumeBot.value.higherRange.from,
        higherTo: volumeBot.value.higherRange.to,
      });
    }
  }, [volumeBot]);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const volumeBotConfig = {
        key: "eiqt_volume_bot_config",
        value: {
          lowerRange: {
            from: values.lowerFrom,
            to: values.lowerTo
          },
          higherRange: {
            from: values.higherFrom,
            to: values.higherTo
          }
        },
      };
      const response = await SettingApi.updateSetting(volumeBotConfig);
      if (response) {
        dispatch(
          CommonSlice.actions.showNotice({
            type: "success",
            message: "Success!",
            description: "Update successful",
          })
        );
      }
    } catch (error) {
      dispatch(
        CommonSlice.actions.showNotice({
          type: "error",
          message: "Error!",
          description: "Update failed",
        })
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <Card title="Volume Bot (eIQT)" bordered={false}>
      <Form form={form} name="volume-bot" onFinish={onFinish} layout="vertical">
        <Row style={{ margin: 0 }} gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Lower from"
              name="lowerFrom"
              rules={[{ required: true, message: "Please input your value!" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Lower to"
              name="lowerTo"
              rules={[{ required: true, message: "Please input your value!" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ margin: 0 }} gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Higher from"
              name="higherFrom"
              rules={[{ required: true, message: "Please input your value!" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Higher to"
              name="higherTo"
              rules={[{ required: true, message: "Please input your value!" }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
