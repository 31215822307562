import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";

const { Text } = Typography;

export default function BalanceView() {
  const { detail } = useAppSelector((state) => state.user);
  return (
    <div className="form-item-container" style={{ display: "flex" }}>
      <Descriptions column={6} title="Balance">
        <Descriptions.Item span={12} label="USDT">
          <Text strong>{detail?.balance}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={12} label="IQT">
          <Text strong>{detail?.tokenBalance}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={12} label="BNB">
          <Text strong>{detail?.bnbBalance}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={12} label="eIQT">
          <Text strong>{detail?.eIQTBalance}</Text>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={6} title="Deposit">
        <Descriptions.Item span={12} label="USDT">
          <Text strong>{detail?.totalDeposit}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={12} label="IQT">
          <Text strong>{detail?.totalTokenDeposit}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={12} label="BNB">
          <Text strong>{detail?.totalBNBDeposit}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={12} label="eIQT">
          <Text strong>{detail?.totalEIQTDeposit}</Text>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={6} title="Withdraw">
        <Descriptions.Item span={12} label="USDT">
          <Text strong>{detail?.totalWithdraw}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={12} label="IQT">
          <Text strong>{detail?.totalTokenWithdraw}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={12} label="BNB">
          <Text strong>{detail?.totalBNBWithdraw}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={12} label="eIQT">
          <Text strong>{detail?.totalEIQTWithdraw}</Text>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={6} title="Transfer">
        <Descriptions.Item span={12} label="USDT">
          <Text strong>{detail?.totalTransfer || 0}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={12} label="IQT">
          <Text strong>{detail?.totalTokenTransfer || 0}</Text>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
