import { Button, Descriptions, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function FarmingInfo() {
  const { detail } = useAppSelector((state) => state.user);

  return (
    <div className="form-item-container">
      <Descriptions column={6} title="Farming Info">
        <Descriptions.Item span={3} label="Farming">
          <Text strong>{detail?.farming?.totalValue || 0} USD</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Income Limit">
          <Text strong>
            {Helpers.checkInt(detail?.farming?.totalIncomeLimit || 0)} USD
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Profit">
          <Text strong>
            {Helpers.checkInt(detail?.farming?.totalInterest || 0)} USD
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Commission">
          <Text strong>
            {Helpers.checkInt(detail?.farming?.totalCommission || 0)} USD
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Commission Lock">
          <Text strong>
            {Helpers.checkInt(detail?.farming?.totalCommissionLock || 0)} USD
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Burned">
          <Text strong>
            {Helpers.checkInt(detail?.farming?.totalIncomeBurned || 0)} USD
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Sales">
          <Text strong>{detail?.farming?.commissionSale || 0} USD</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Sale Level">
          <Text strong>
            {Helpers.checkInt(detail?.farming?.commissionLevel || 0)}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Claimable">
          <Text strong>
            {Helpers.checkInt(detail?.farming?.claimableBalance || 0)}{" "}
            USD
          </Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Claimed">
          <Text strong>
            {Helpers.checkInt(detail?.farming?.totalClaimed || 0)} USD
          </Text>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
