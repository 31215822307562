import { Radio, Space } from "antd";
import CommissionHistories from "pages/users/CommissionHistories/CommissionHistories";
import FriendVolume from "pages/users/FriendVolume/FriendVolume";
import InterestHistories from "pages/users/InterestHistories/InterestHistories";
import PredictHistories from "pages/users/PredictHistories/PredictHistories";
import StakeHistories from "pages/users/StakeHistories/StakeHistories";
import queryString from "query-string";
import { isEmpty } from "ramda";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CoinTransactionHistories from "../../TransactionHistories";
import FarmingHistories from "../../FarmingHistories/FarmingHistories";
import FarmingCommissionHistories from "../../FarmingCommissionHistories/FarmingCommissionHistories";
import FarmingInterestHistories from "../../FarmingInterestHistories/FarmingInterestHistories";

export default function ListOptions() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [tabSelected, setTabSelected] = useState("transaction-histories");
  const query: any = useMemo(() => {
    const data: any = queryString.parse(location.search);
    return {
      tab: data.tab ? data.tab : "transaction-histories",
    };
  }, [location.search]);

  const onChangeTab = (e: any) => {
    setTabSelected(e.target.value);
    navigate({
      search: queryString.stringify({ tab: e.target.value }),
    });
  };

  useEffect(() => {
    if (!isEmpty(query)) {
      setTabSelected(query.tab);
    }
  }, [query]);

  return (
    <div className="form-item-container">
      <Radio.Group
        value={tabSelected}
        buttonStyle="solid"
        style={{ marginBottom: 15 }}
        onChange={onChangeTab}
      >
        <Space style={{ marginBottom: 5 }}>
          <Radio.Button value="transaction-histories">
            Deposit & Withdraw
          </Radio.Button>
          <Radio.Button value="predict-histories">
            Predict histories
          </Radio.Button>
          <Radio.Button value="commission-rebate">
            Prediction commission
          </Radio.Button>
          <Radio.Button value="stake">Stake histories</Radio.Button>
          <Radio.Button value="interest">Interest histories</Radio.Button>
          <Radio.Button value="stake-commission">
            Stake commission histories
          </Radio.Button>
        </Space>
        <Space style={{marginBottom: 5}}>
          <Radio.Button value="farming">Farming histories</Radio.Button>
          <Radio.Button value="farming-interest">Farming Interest Histories</Radio.Button>
          <Radio.Button value="farming-commission">Farming Commission Histories</Radio.Button>
        </Space>
      </Radio.Group>
      <RenderComponent tabSelected={tabSelected} userId={params.id || ""} />
    </div>
  );
}

const RenderComponent = ({
  tabSelected,
  userId,
}: {
  tabSelected: string;
  userId: string;
}) => {
  if (userId) {
    switch (tabSelected) {
      case "transaction-histories":
        return <CoinTransactionHistories userId={userId} />;
      case "predict-histories":
        return <PredictHistories userId={userId} />;
      case "commission-rebate":
        return <FriendVolume userId={userId} />;
      case "stake":
        return <StakeHistories userId={userId} />;
      case "interest":
        return <InterestHistories userId={userId} />;
      case "stake-commission":
        return <CommissionHistories userId={userId} />;
      case "farming":
        return <FarmingHistories userId={userId} />;
      case "farming-commission":
        return <FarmingCommissionHistories userId={userId} />;
      case "farming-interest":
        return <FarmingInterestHistories userId={userId} />;
    }
  }
  return null;
};
