export const filterOptions = [
  {
    label: "Status",
    value: "withStatus",
  },
  {
    label: "Token",
    value: "withCoinType",
  },
  {
    label: "Round",
    value: "withRound",
  },
];

export const UserPredictStatus = [
  {
    label: "WIN",
    value: "WIN",
  },
  {
    label: "LOSE",
    value: "LOST",
  },
  {
    label: "PENDING",
    value: "NONE",
  },
];

export const PredictCoinType = [
  {
    label: "USDT",
    value: "USDT",
  },
  {
    label: "IQT",
    value: "IQT",
  },
  {
    label: "eIQT",
    value: "eIQT",
  },
];
