import { Descriptions, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function EIQTPredictionInfo() {
  const {userEIQTPredictPnl } = useAppSelector((state) => state.user);
  return (
    <div className="form-item-container">
      <Descriptions column={6} title="Prediction PNL (eIQT)">
        <Descriptions.Item span={3} label="Win">
          <Text strong>{`${userEIQTPredictPnl?.totalWin || 0} Round / ${
            userEIQTPredictPnl?.totalAmountWin || 0
          } eIQT`}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Loss">
          <Text strong>{`${userEIQTPredictPnl?.totalLost || 0} Round / ${
            userEIQTPredictPnl?.totalAmountLost || 0
          } eIQT`}</Text>
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Volume">
          <Text strong>
            {Helpers.checkInt(userEIQTPredictPnl?.totalAmount ?? 0)} eIQT
          </Text>
        </Descriptions.Item>

        <Descriptions.Item span={3} label="PnL">
          {userEIQTPredictPnl ? (
            <Text
              type={
                userEIQTPredictPnl.totalAmountWin - userEIQTPredictPnl.totalAmountLost >
                0
                  ? "success"
                  : "danger"
              }
              strong
            >
              {Helpers.checkInt(
                userEIQTPredictPnl.totalAmountWin - userEIQTPredictPnl.totalAmountLost
              )}{" "}
              eIQT
            </Text>
          ) : (
            <Text strong>0 eIQT</Text>
          )}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
