import {Descriptions, Divider, Typography} from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";

const { Text } = Typography;

export default function GeneralView() {
  const { general } = useAppSelector((state) => state.general);
  return (
    <div className="form-item-container">
      <Descriptions title="Prediction USD" column={2}>
        <Descriptions.Item label="License Sales" span={2}>
          <Text strong>{Helpers.checkInt(general?.totalLicense || 0)}</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Trading Volume">
          <Text strong>{Helpers.checkInt(general?.totalVolume || 0)} USD</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Commission">
          <Text strong>{Helpers.checkInt(general?.totalCommission || 0)} USD</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Winning">
          <Text strong>{Helpers.checkInt(general?.totalAmountWin || 0)} USD</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Loss">
          <Text strong>{general?.totalAmountLose || 0} USD</Text>
        </Descriptions.Item>
      </Descriptions>
      <Divider/>
      <Descriptions title="Prediction IQT" column={2}>
        <Descriptions.Item label="Trading Volume">
          <Text strong>{Helpers.checkInt(general?.totalTokenVolume || 0)} IQT</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Commission">
          <Text strong>{Helpers.checkInt(general?.totalTokenCommission || 0)} IQT</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Winning">
          <Text strong>{Helpers.checkInt(general?.totalTokenAmountWin || 0)} IQT</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Loss">
          <Text strong>{general?.totalTokenAmountLose || 0} IQT</Text>
        </Descriptions.Item>
      </Descriptions>
      <Divider/>
      <Descriptions title="Prediction eIQT" column={2}>
        <Descriptions.Item label="Trading Volume">
          <Text strong>{Helpers.checkInt(general?.totalEIQTVolume || 0)} eIQT</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Commission">
          <Text strong>{Helpers.checkInt(general?.totalEIQTCommission || 0)} eIQT</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Winning">
          <Text strong>{Helpers.checkInt(general?.totalEIQTAmountWin || 0)} eIQT</Text>
        </Descriptions.Item>
        <Descriptions.Item label="Loss">
          <Text strong>{general?.totalEIQTAmountLose || 0} eIQT</Text>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}
