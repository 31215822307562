import React, { useEffect, useMemo, useState } from "react";
import { Card, Progress, Spin, Tag, Typography } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import moment from "moment";
import { useAppSelector } from "stores";
import { Helpers } from "utils";
import HeaderRound from "./HeaderRound";
import _ from "lodash";
const { Text } = Typography;

type Props = {
  round: RoundPredictType;
};

export default function LiveRound(props: Props) {
  const { round } = props;
  const [progress, setProgress] = useState(0);
  const lastPrice = useAppSelector((state) => state.prediction).lastPrice;
  const isLiveProcessing = useAppSelector(
    (state) => state.prediction
  ).isLiveProcessing;

  const calculateChangePrice = (
    lastPrice: number,
    round: RoundPredictType
  ): number => {
    return lastPrice - round.lockPrice;
  };

  const change: number = useMemo(
    () => calculateChangePrice(lastPrice, round),
    [lastPrice, round]
  );

  useEffect(() => {
    if (!round) return;
    const countDownInterval = setInterval(() => {
      const totalSecond = moment(round.closeTime || "").diff(
        round.lockTime || "",
        "ms"
      );
      const progress = moment().diff(round.lockTime || "", "ms");
      const percent = Math.floor((progress / totalSecond) * 100);
      setProgress(percent);
    }, 500);
    return () => clearInterval(countDownInterval);
  }, [round]);

  return (
    <Spin spinning={isLiveProcessing}>
      <div style={{ border: "1px solid #000", padding: 16 }}>
        <HeaderRound round={round} />
        <Progress
          className={"live-progress"}
          strokeLinecap="butt"
          percent={progress}
          showInfo={false}
          trailColor="#6b6b6b"
          strokeColor="#FCD535"
        />
        <div style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text strong type={change > 0 ? "success" : undefined}>
              UP
            </Text>
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              <Text strong style={{ width: 60 }}>
                Payout:
              </Text>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text strong type={change > 0 ? "success" : undefined}>
                  {Helpers.checkInt(round.rateUpToken + 1)}x IQT
                </Text>
                <Text strong type={change > 0 ? "success" : undefined}>
                  {Helpers.checkInt(round.rateUpEIQT + 1)}x eIQT
                </Text>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 5 }}
            >
              <Text strong>Amount: </Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                }}
              >
                <Text strong>
                  {Helpers.checkInt(round.userUpTokenAmount)} IQT
                </Text>
                <Text strong>
                  {Helpers.checkInt(round.userUpEIQTAmount)} eIQT
                </Text>
              </div>
            </div>
          </div>
          <Card
            style={{
              width: 300,
              borderWidth: 2,
              borderColor: `${
                change > 0 ? "#189F6A" : change < 0 ? "#DB1932" : ""
              }`,
            }}
            bordered
          >
            <div>
              <Text strong>LAST PRICE</Text>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "10px 0",
              }}
            >
              <Text
                style={{ fontSize: 24 }}
                strong
                type={change > 0 ? "success" : "danger"}
              >
                ${lastPrice}
              </Text>
              <Tag
                icon={change > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                color={change > 0 ? "#189F6A" : "#DB1932"}
                style={{ marginRight: 0 }}
              >
                {change > 0 ? "UP" : "DOWN"}
              </Tag>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text>Locked Price</Text>
              <Text strong>$ {Helpers.checkInt(round.lockPrice)}</Text>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Text>Real Prize Pool</Text>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Text strong>
                  {Helpers.checkInt(
                    round.userUpTokenAmount + round.userDownTokenAmount
                  )}{" "}
                  IQT
                </Text>
                <Text strong>
                  {Helpers.checkInt(
                    round.userUpEIQTAmount + round.userDownEIQTAmount
                  )}{" "}
                  eIQT
                </Text>
              </div>
            </div>
          </Card>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text strong type={change < 0 ? "danger" : undefined}>
              DOWN
            </Text>
            <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
              <Text strong style={{ width: 60 }}>
                Payout:
              </Text>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text strong type={change < 0 ? "danger" : undefined}>
                  {Helpers.checkInt(round.rateDownToken + 1)}x IQT
                </Text>
                <Text strong type={change < 0 ? "danger" : undefined}>
                  {Helpers.checkInt(round.rateDownEIQT + 1)}x eIQT
                </Text>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "row", marginTop: 5 }}
            >
              <Text strong>Amount: </Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                }}
              >
                <Text strong>
                  {Helpers.checkInt(round.userDownTokenAmount)} IQT
                </Text>
                <Text strong>
                  {Helpers.checkInt(round.userDownEIQTAmount)} eIQT
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
