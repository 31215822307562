import { Table, Typography } from "antd";
import React from "react";
import { useAppSelector } from "stores";
import { Helpers } from "utils";
import moment from "moment";

const { Text } = Typography;

type Props = {
  onChangePage: (page: number) => void;
  filter: any;
};

export default function List(props: Props) {
  const datas = useAppSelector((state) => state.user).farmingHistory.datas;
  const meta = useAppSelector((state) => state.user).farmingHistory.meta;

  const columns: any = [
    {
        title: "ID",
        dataIndex: "shortId",
        key: "shortId",
        align: "center",
    },
    {
      title: "Value (USD)",
      dataIndex: "value",
      key: "value",
      align: "center",
    },
    {
      title: "Income Limit (USD)",
      dataIndex: "incomeLimit",
      key: "incomeLimit",
      align: "center",
    },
    {
      title: "Income (USD)",
      dataIndex: "totalIncome",
      key: "totalIncome",
      align: "center",
    },
    {
      title: "Profit (USD)",
      dataIndex: "totalInterest",
      key: "totalInterest",
      align: "center",
    },
    {
      title: "Commission (USD)",
      dataIndex: "totalCommission",
      key: "totalCommission",
      align: "center",
    },
      {
          title: "status",
          dataIndex: "status",
          key: "status",
          align: "center",
      },
      {
          title: "Time",
          dataIndex: "createdAt",
          key: "createdAt",
          align: "center",
          render: (value: Date) => moment(value).format("YYYY-MM-DD hh:mm:ss"),
      },
  ];

  return (
    <div className="list-container">
      <Table
        columns={columns}
        dataSource={datas.map((item) => ({ key: item.id, ...item }))}
        pagination={{
          defaultPageSize: props.filter ? props.filter.pageSize : 5,
          defaultCurrent: props.filter ? props.filter.page : 1,
          current: props.filter ? props.filter.page : 1,
          showSizeChanger: false,
          total: meta?.total,
          onChange: (page) => props.onChangePage(page),
          showTotal: (total: number) => `Total: ${total}`,
        }}
      />
    </div>
  );
}
